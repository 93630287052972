import React from 'react';
import "./Tables.css";

const Tables = ({ data }) => {
  const hiddenColumns = ['file_url', 'status'];
  const columns = Object.keys(data[0]).filter(col => !hiddenColumns.includes(col)); 

  const handleClick = (filePath) => {
    // alert(`Email clicked: ${filepath}`);
    window.open(filePath, '_blank');
  };

  return (
    <table className="employee-table">
      <thead>
        <tr>
          {columns.map((col) => (
            <th key={col}>{col.replace('_', ' ').toUpperCase()}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            {columns.map((col) => (
              <td key={col}>
                {col === 'filePath' ? (
                  <span
                    onClick={() => handleClick(row[col])}
                    style={{ color: 'blue', cursor: 'pointer' }}
                  >
                    {/* {row[col]} */}
                    Download
                  </span>
                ) : (
                  row[col]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Tables;
