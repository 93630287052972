import React, { useEffect, useState } from 'react';
import Tables from './components/Tables';


const App = () => {
    const liveServer = "https://newwebservices.docmaster.in:4000";
    // const liveServer = "http://192.168.0.141:4000";
    const [data, setData] = useState([]);

    useEffect(function(){
        getAllCareers()
    }, []);

    async function getAllCareers() {
        const res = await fetch(`${liveServer}/api/career/getAllCareer`);
        const data = await res.json();
        setData(data);
    }

  return (
    <div className="app-container">
      {data?.length > 0 && <Tables data={data} />}
      {data.length === 0 && (
        <div>Contact Team</div>
      )}
    </div>
  );
};

export default App;
